




































































































































import { useTypologies } from '@/composition/typologies'
import { typologyService } from '@/services/modules/typology'
import {
  TypologyTableType,
  TypologyViewModel,
} from '@/services/modules/typology'
import i18n from '@/setup/i18n'
import { VueGoodTableColumn } from '@/setup/vue-good-table'
import { useCurrency } from '@/composition/currency'
import { enUs, esMx } from '@/utils/language'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import communique from '@/notification'
import { defineComponent, computed, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'Typologies',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  setup() {
    const editMode = ref(false)

    const isSubmitting = ref(false)

    const form = ref<HTMLFormElement | null>(null)

    const model = ref<TypologyTableType[]>([])

    const priceLabel = computed(() => i18n.t('price') as string)

    const setCurrency = (price: number) => {
      const priceUsd = useCurrency({
        value: price,
        locale: enUs.locale,
      }).value
      const priceMxn = useCurrency({
        value: price,
        locale: esMx.locale,
      }).value
      return `${priceUsd} (${priceMxn})`
    }

    const columns = computed<VueGoodTableColumn<TypologyViewModel>[]>(() => [
      {
        label: i18n.t('code') as string,
        field: 'code',
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        label: i18n.t('description') as string,
        field: 'description',
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        label: i18n.t('price') as string,
        field: 'price',
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        label: i18n.t('soldOut') as string,
        field: 'isSoldOut',
        thClass: 'text-center',
        tdClass: 'text-center',
      },
    ])

    const {
      data: rows,
      isLoading,
      totalItems,
      error,
    } = useTypologies({
      pageNumber: 1,
      rowsPerPage: -1,
    })

    function enableEditMode() {
      editMode.value = true

      rows.value.forEach((row) => {
        model.value.push({
          typologyId: row.typologyId,
          price: row.price,
          isSoldOut: row.isSoldOut,
        })
      })
    }

    async function submit() {
      const typologiesToUpdate: TypologyTableType[] = []

      rows.value.forEach((row, index) => {
        if (
          row.price !== model.value[index].price ||
          row.isSoldOut !== model.value[index].isSoldOut
        )
          typologiesToUpdate.push(model.value[index])
      })

      if (typologiesToUpdate.length === 0) return

      try {
        isSubmitting.value = true

        await typologyService.update({ typologyTableType: model.value })

        communique.dispatch({
          variant: 'success',
          message: i18n.t('typologyUpdateSuccess') as string,
        })

        typologiesToUpdate.forEach((t) => {
          let idx = rows.value.findIndex((el) => el.typologyId === t.typologyId)
          if (idx !== -1) {
            rows.value[idx].price = t.price
            rows.value[idx].isSoldOut = t.isSoldOut
          }
        })

        editMode.value = false
      } catch (error) {
        communique.dispatch({
          variant: 'error',
          message: i18n.t('typologyUpdateError') as string,
        })
      } finally {
        isSubmitting.value = false
      }
    }

    return {
      rows,
      isLoading,
      totalItems,
      error,
      columns,
      editMode,
      enableEditMode,
      setCurrency,
      submit,
      model,
      isSubmitting,
      priceLabel,
      form,
    }
  },
})
