var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{ref:"form",staticClass:"\n      grid\n      gap-6\n      sm:rounded-md sm:overflow-hidden\n      px-4\n      py-5\n      sm:p-6\n      bg-white\n    ",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('BaseBox',{staticClass:"grid gap-4"},[_c('BaseBox',{staticClass:"flex justify-end"},[_c('BaseButton',{attrs:{"variant":"solid","tone":"tertiary"},on:{"click":function($event){return _vm.enableEditMode()}}},[_c('BaseIcon',{attrs:{"name":"edit"}})],1)],1),_c('BaseTable',{attrs:{"mode":"remote","total-rows":_vm.totalItems,"is-loading":_vm.isLoading,"sort-options":{
          enabled: false,
        },"pagination-options":{
          enabled: false,
        },"rows":_vm.rows,"columns":_vm.columns,"style-class":"vgt-table striped"},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
        var column = ref.column;
        var formattedRow = ref.formattedRow;
        var row = ref.row;
return [(column.field === 'code')?[_c('BaseText',{staticClass:"text-tertiary"},[_vm._v(" "+_vm._s(row.code)+" ")])]:(column.field === 'price')?[(!_vm.editMode)?[_c('BaseText',[_vm._v(" "+_vm._s(_vm.setCurrency(row.price))+" ")])]:[_c('BaseBox',{staticClass:"w-full md:w-1/2 mx-auto"},[_c('ValidationProvider',{attrs:{"name":_vm.priceLabel,"rules":{ required: true, min_value: 0 },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('BaseFieldLabel',{attrs:{"label":"","tertiary-label":_vm.setCurrency(_vm.model[row.originalIndex].price),"html-for":("price-" + (row.code))}}),_c('BaseInputText',{attrs:{"id":("price-" + (row.code)),"type":"number","min":"0"},model:{value:(_vm.model[row.originalIndex].price),callback:function ($$v) {_vm.$set(_vm.model[row.originalIndex], "price", _vm._n($$v))},expression:"model[row.originalIndex].price"}}),_c('BaseFieldMessage',{attrs:{"id":("price-message-" + (row.code)),"message":errors[0],"tone":"critical"}})]}}],null,true)})],1)]]:(column.field === 'isSoldOut')?[(!_vm.editMode)?[_c('BaseInputCheckbox',{attrs:{"id":("soldout-" + (row.code)),"disabled":""},model:{value:(_vm.rows[row.originalIndex].isSoldOut),callback:function ($$v) {_vm.$set(_vm.rows[row.originalIndex], "isSoldOut", $$v)},expression:"rows[row.originalIndex].isSoldOut"}})]:[_c('BaseInputCheckbox',{attrs:{"id":("soldout-" + (row.code))},model:{value:(_vm.model[row.originalIndex].isSoldOut),callback:function ($$v) {_vm.$set(_vm.model[row.originalIndex], "isSoldOut", $$v)},expression:"model[row.originalIndex].isSoldOut"}})]]:[_vm._v(" "+_vm._s(formattedRow[column.field])+" ")]]}}],null,true)}),(_vm.editMode)?_c('BaseBox',{staticClass:"flex justify-end"},[_c('BaseButton',{attrs:{"loading":_vm.isSubmitting,"variant":"solid","tone":"tertiary","type":"submit"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":'submitUpdate'}})]},proxy:true},{key:"loading-text",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":'submitUpdateButtonLoading'}})]},proxy:true}],null,true)})],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }